.hero-logo-container {
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 50px;
  width: 50px;
}
.hero-logo {
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
}
.hero-header {
  font-size: 55px;
  line-height: 57px;
  font-weight: 700;
}
