.image-thumbnail {
  max-width: 125px;
  height: 165px;
  object-fit: contain;
}

/*shelf styles*/
.shelf-container {
  border: 5px solid #582e0d;
  border-radius: 10px;
}
.shelf-row-separator {
  border-bottom: 3px solid #592e0d;
}
.shelf-row-back {
  background: #b97540;
}
.shelf-row-base {
  background: #9c6338;
  position: absolute;
  bottom: 0;
  height: 25px;
  width: 100%;
  z-index: 1;
}
.shelf-row-side {
  height: 100%;
  width: 25px;
  background: #ca834e;
  position: absolute;
  z-index: 1;
}
.shelf-corner {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 25px solid #ca834e;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
}
