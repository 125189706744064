.skill-matrix {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  padding: 25px 25px 0 25px;
  border-radius: 10px;
}

.skill-matrix > * {
  min-width: 202px;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .skill-matrix > * {
    min-width: 100%;
  }
}
