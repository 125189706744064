* {
  box-sizing: border-box;
}
@font-face {
  font-family:'ExoSpace';
  font-style: normal;
  font-weight: 400;
  src: local('ExoSpace'), local('ExoSpace'),
  url(../public/fonts/exospace/ExoSpace.ttf) format('truetype');
}
@font-face {
  font-family:'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
  url(../public/fonts/Roboto/Roboto-Light.ttf) format('truetype');
}
@font-face {
  font-family:'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Regular'), local('Roboto-Regular'),
  url(../public/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}
@font-face {
  font-family:'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url(../public/fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}
.font-family-secondary {
  font-family: ExoSpace, serif;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-space-around {
  justify-content: space-around;
}
.container-fluid {
  min-height: 100vh;
  margin-right: auto;
  margin-left: auto;
}
.container {
  width: 100%;
  position: relative;
  max-width: 1100px;
  height: 100%;
  margin: auto;
  display: flex;
}
.mb-md {
  margin-bottom: 10px;
}
.mr-md {
  margin-right: 10px;
}
.mb-l {
  margin-bottom: 18px;
}
.mb-xl {
  margin-bottom: 26px;
}
.icon-container {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.icon-container svg {
  width: 100%;
  height: 100%;
}
a, a:focus, a:hover, a:visited {
  color: #0969da !important;
}
.relative {
  position: relative;
}
.cursor-pointer {
  cursor: pointer;
}
